import { atom, selector } from "recoil";
const TOKEN_KEY = 'jwtToken';
const BO_USER = 'dcUser';


export const isLoggedin = selector({
  key: 'isLoggedin',
  get: ({get}) => {
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }
    else
    {
      return false;
    }
  },
});

export const userState = selector({
  key: 'userState',
  get: ({get}) => {
    if (localStorage.getItem(BO_USER)) {
        return localStorage.getItem(BO_USER);
    }
    else
    {
      return null;
    }
  },
});

export const authState = atom({
  key: 'authState',
  default: '0',
});



export const animeTitles = atom({
  key: "animeTitleList",
  default: [],
});
