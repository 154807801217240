import React, { Component } from 'react';
import { withTranslation, Translation, } from 'react-i18next';

import {BrowserView,MobileView,TabletView, isMobileOnly, isTablet} from 'react-device-detect';

import { View, ActivityIndicator} from "react-native-web";

import HeaderPC from '../../header/HeaderPC';
import HeaderMobile from '../../header/HeaderMobile';
import HeaderTablet from '../../header/HeaderTablet';

const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

export default function LoadingPage() {




    return (

	         <div>


                   {!isMobileOnly && !isTablet &&
                     <BrowserView>
                       <View style={{width:viewportWidth, height: viewportHeight, justifyContent: 'center', alignSelf: 'center', backgroundColor: '#ecedf0'}}>

                         <HeaderPC />

                         <div style={{textAlign: 'center', marginBottom: '30px'}}><img src={'https://d3van47cb9u9tj.cloudfront.net/debyooid/cdpresse_logo_tag_fr.png'} alt="CdPresse Logo" style={{width:'380px'}}/></div>



                         <ActivityIndicator  animating={true} color="#0079ff" />
                       </View>
                    </BrowserView>
                  }



                  {isMobileOnly &&
                   <MobileView>
                     <View style={{width:viewportWidth, height: viewportHeight, justifyContent: 'center', alignSelf: 'center', backgroundColor: '#ecedf0'}}>


                       <HeaderMobile />


                       <div style={{textAlign: 'center', marginBottom: '30px'}}><img src={'https://d3van47cb9u9tj.cloudfront.net/debyooid/cdpresse_logo_tag_fr.png'} alt="CdPresse Logo" style={{width:'130px'}}/></div>


                       <ActivityIndicator  animating={true} color="#0079ff" />
                     </View>
                  </MobileView>
                 }

                 {isTablet &&
                   <TabletView>
                       <View style={{width:viewportWidth, height: viewportHeight, justifyContent: 'center', alignSelf: 'center', backgroundColor: '#ecedf0'}}>

                           <HeaderTablet />

                         <div style={{textAlign: 'center', marginBottom: '30px'}}><img src={'https://d3van47cb9u9tj.cloudfront.net/debyooid/cdpresse_logo_tag_fr.png'} alt="CdPresse Logo" style={{width:'380px'}}/></div>



                         <ActivityIndicator  animating={true} color="#0079ff" />
                       </View>
                   </TabletView>
                 }



	        </div>

    )

}
