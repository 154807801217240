import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from "recoil";

import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

global.appName = 'CdPresse';
global.appCateg = 'CdPresse Media';
global.city = '';
global.region = '';
global.country = 'CD';
global.continent = 'AF';
global.ip = '';
global.reloadobj = null;
global.rate = 2060;

global.displayModal = false;
global.selectedFilter = '';
global.menuFilterClicked = false;

//const stripePromise = loadStripe('pk_live_1NtV1g1sGnZEVL2BfkAxsSiu');
const stripePromise = loadStripe('pk_test_6qz6Ir009Ktjzv1MIRZBATUV');


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <Elements stripe={stripePromise} options={{ locale:"fr" }}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
  </Elements>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
